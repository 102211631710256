.seo-1 {
  margin: 6rem auto 0 auto;
  max-width: 1800px;
  padding: 0;

  @media (min-width: $sm-width) {
    margin: 4rem auto 0 auto;
  }

  &-strong {
    align-items: center;
    display: flex;
    font-weight: bold;
    height: 150px;
    justify-content: center;
    margin: auto;
    position: relative;
    width: 70%;

    @media (min-width: $sm-width) {
      font-size: 4rem;
      width: 350px;
      height: 200px;
    }
    &-title {
      span {
        color: color(text);
        font-size: 3rem;
        font-weight: 100;
        font-family: default(font-family, light);

        @media (min-width: $md-width) {
          font-size: 4rem;
        }
      }
    }

    &-corner-top {
      border-left: 1px solid #000;
      border-top: 1px solid #000;
      height: 50px;
      left: 0;
      position: absolute;
      top: 0;
      width: 50px;
    }

    &-corner-bottom {
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      bottom: 0;
      height: 50px;
      position: absolute;
      right: 0;
      width: 50px;
    }
  }

  .text {
    line-height: 2.4rem;
    margin: 5rem auto 0;
    text-align: center;
    width: 70%;
    color: color(text, medium);
    font-size: 1.2rem;
    display: none;
  }
}
