.carousel {
  text-align: center;
  position: relative;
  margin: 0 0 7vw 0;
  p {
    padding: 2vw 10vw 3vw 10vw;
  }

  .slider {
    height: 30vw;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .imgAnt {
      position: absolute;
      top: 3.5vw;
      left: 0;
      width: 25%;
      height: 22vw;
      z-index: 0;
      cursor: pointer;
      filter: blur(2px) brightness(60%);
      transition: filter 0.5s ease;

      &:hover {
        filter: blur(0px) brightness(100%);
      }
    }
    .imgActual {
      width: 50%;
      position: absolute;
      z-index: 10;
      left: 25%;
      overflow: hidden;
      height: 29vw;
      box-shadow: 1vw 1vw 4vw rgba(0, 0, 0, 0.5),
        -0.3vw -0.3vw 2vw rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.5s ease;
    }
    .imgSig {
      position: absolute;
      top: 3.5vw;
      right: 0%;
      width: 25%;
      height: 22vw;
      z-index: 0;
      cursor: pointer;
      filter: blur(2px) brightness(60%);
      transition: 0.5s filter ease;
      &:hover {
        filter: blur(0px) brightness(100%);
      }
    }
    .hidden {
      display: none;
      position: absolute;
      top: 3.5vw;
      width: 25%;
      height: 22vw;
      z-index: 0;
      cursor: pointer;
      filter: blur(2px) brightness(60%);
      transition: 0.5s filter ease;
    }
  }

  .points {
    margin: 3vw 0 0 0;
    .icon {
      display: inline-block;
      margin: 0 0.5vw;
      font-size: 1.2rem;
      color: #ccc;
      transition: color 0.3s ease-in-out;
      &.active {
        color: #ffe001;
      }
    }
  }
}

@media (max-width: 600px) {
  #slider_home {
    margin: 0 0 0vw 0;
  }
  #slider_home h2 {
    padding: 5vw 0 7vw 0;
  }
  #slider_home p {
    padding: 7vw 10vw 13vw 10vw;
    text-align: justify;
  }
  #slider_home .slider {
    height: 120vw;
    position: relative;
  }
  #slider_home .slider .imgActual {
    left: 0;
    width: 100%;
    position: relative;
    z-index: 10;
    overflow: hidden;
    height: 70vw;
    box-shadow: 1vw 1vw 4vw rgba(0, 0, 0, 0.5),
      -0.3vw -0.3vw 2vw rgba(0, 0, 0, 0.1);
  }
  #slider_home .slider .imgAnt {
    top: 80vw;
    height: 30vw;
    width: 30%;
    left: 5%;
    filter: blur(0px) brightness(100%);
  }

  #slider_home .slider .imgSig {
    top: 80vw;
    height: 30vw;
    width: 30%;
    right: 5%;
    filter: blur(0px) brightness(100%);
  }
  #slider_home .slider .hidden {
    top: 80vw;
    height: 30vw;
    width: 30%;
    filter: blur(0px) brightness(100%);
  }
  #slider_home .puntos {
    margin: 3vw 0 0 0;
  }
  #slider_home .puntos i {
    display: inline-block;
    margin: 0 1vw;
  }
  #slider_home .puntos i svg {
    width: 3vw;
  }
  #slider_home .puntos i svg path {
    fill: #ccc;
    transition: 0.5s ease-in-out;
  }
  #slider_home .puntos .select svg path {
    fill: #ffe001;
  }
}
