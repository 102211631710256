.labeled-image-card {
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 100%;

  &__label {
    background-color: color(background, dark-light);
    position: absolute;
    width: 90%;
    height: 90%;
    margin: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    padding: 2rem;
    top: 0;
    z-index: 2;

    h3 {
      color: color(mono, light);
      font-family: default(font-family, fancy);
      font-size: 3rem;
      transition: color 0.3s ease;
      font-weight: 100;
      text-align: center;

      @media (min-width: $xs-width) {
        font-size: 2.6rem;
        line-height: 4rem;
      }

      @media (min-width: 600px) {
        font-size: 3.2rem;
        line-height: 5rem;
      }

      @media (min-width: $sm-width) {
        font-size: 4rem;
        line-height: 6rem;
      }

      @media (min-width: $md-width) {
        font-size: 6rem;
        line-height: 8rem;
      }
    }
  }
  &__image {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    img {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
