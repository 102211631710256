.btn {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;

  &-primary {
    background-color: color(primary);
    border: 2px solid color(primary);
    border-radius: 10rem;
    color: color(mono, light);
    font-size: 1.4rem;
    padding: 01rem 3rem;
    text-transform: uppercase;
    transition: color 0.3s ease, background-color 0.3s ease;

    @media (min-width: $xxl-width) {
      font-size: 1.6rem;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: color(mono, light);
      color: color(primary);
    }
  }

  &-secondary {
    border: 1px solid color(primary);
    color: color(primary);
    font-family: default(font-family);
    font-size: 2rem;
    outline: none;
    padding: 01rem 3rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.5s ease, color 0.5s ease;

    &:hover {
      background-color: color(primary);
      color: color(mono, light);
    }

    &-light {
      border: 1px solid color(mono, light) !important;
      color: color(mono, light);
      text-transform: none;
      font-family: default(font-family, secondary);
      font-weight: 700;

      &:hover,
      &:focus,
      &:active {
        background-color: color(mono, light);
        color: color(tertiary);
      }
    }
  }

  &-tertiary {
    background-color: color(quaternary);
    border: 2px solid color(quaternary);
    border-radius: 10rem;
    color: color(mono, light);
    font-size: 2rem;
    padding: 1rem 3rem;
    text-transform: uppercase;
    transition: color 0.3s ease, background-color 0.3s ease;
    font-weight: 800;

    &:hover,
    &:focus,
    &:active {
      color: color(mono, light);
    }
  }
}
