.support-photo {
  .images {
    display: block;
    margin: auto;
    margin-bottom: 3rem;
    text-align: left;
    width: 90%;

    .img {
      display: inline-block;
      height: 20rem;
      width: 20rem;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
