.slider-big {
  position: relative;
  width: 100%;

  &-container {
    background-color: black;
    height: 152vw;
    margin: auto;
    position: relative;
    width: 100%;

    @media (min-width: $sm-width) {
      height: 54vw;
    }

    @media (min-width: $md-width) {
      height: 46vw;
    }
  }

  .swiper {
    height: 100%;
  }

  &-slide {
    height: 100%;
    opacity: 100%;
    // position: absolute;
    width: 100%;
    z-index: 90;

    &.stage {
      z-index: 101;
      display: block;
    }

    &.active {
      opacity: 100%;
      z-index: 100;
      display: block;
    }

    &__logo {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      background-color: color(tertiary);

      &__inner {
        width: 40%;
      }
      &__img {
        width: 100%;
      }
    }
  }

  &-img {
    height: 100%;
    width: 100%;
    z-index: 2;
    position: relative;
    color: color(tertiary);

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &-card {
    background-color: color(mono, darksoft);
    // height: calc(100% - 60px);
    height: 100%;
    left: 0;
    position: absolute;
    top: auto;
    width: 100%;
    bottom: 0;
    z-index: 3;

    @media (min-width: $sm-width) {
      height: calc(100% - 130px);
      height: 100%;
      width: 50%;
    }

    @media (min-width: $md-width) {
      height: calc(100% - 130px);
      height: 100%;
      width: 50%;
    }

    &-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      padding: 6rem 2rem 0;
      text-align: center;
      width: 100%;

      @media (min-width: $sm-width) {
        padding: 0 4rem 0;
      }
    }
    &-text {
      color: color(mono, light);
      font-style: italic;
      width: 100%;
      // height: 33%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-family: default(font-family, secondary);
      font-size: 3rem;
      line-height: 3.5rem;

      @media (min-width: $xxl-width) {
        font-size: 4rem;
        line-height: 4.5rem;
      }
    }

    &-title {
      // height: 33%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: color(text, light);
      font-size: 5rem;
      line-height: 5rem;
      font-stretch: condensed !important;
      font-weight: 100;

      @media (min-width: $xs-width) {
        font-size: 7rem;
        line-height: 5rem;
      }

      @media (min-width: $sm-width) {
        font-size: 5rem;
        line-height: 5rem;
      }

      @media (min-width: $xl-width) {
        font-size: 8rem;
        line-height: 9rem;
      }
    }

    &-button {
      width: 100%;
      // height: 25%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &-pagination {
    bottom: 3rem;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 101;

    @media (min-width: $sm-width) {
      bottom: 1rem;
    }

    .icon {
      color: color(mono, light);
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
      margin: 0 0.5rem;
      transition: color 0.3s ease;
      z-index: 10000000000;

      @media (min-width: $sm-width) {
        font-size: 0.7rem;
        margin: 0 0.1rem;
      }

      &:hover,
      &.active {
        color: color(primary);
      }
    }
  }
}
