.main-footer {
  color: color(text);

  &__content {
    background-color: color(secondary);
    padding: 4rem 2rem;

    @media (min-width: $md-width) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 4rem 0 2rem 0;
    }

    &__separator {
      border-bottom: 1px solid color(border);
      margin: 4rem 0;
      @media (min-width: $md-width) {
        height: 18rem;
        border-right: 1px solid color(border);
      }
    }
  }

  &__about,
  &__nav {
    width: 100%;
    @media (min-width: $md-width) {
      width: 550px;
      padding: 1rem 3rem;
    }
  }

  &__about {
    text-align: center;
    @media (min-width: $md-width) {
      text-align: right;
    }

    &__title {
      padding-bottom: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &__subtitle {
      padding-bottom: 1rem;
      font-weight: 400;
      font-size: 1.4rem;
      text-transform: uppercase;
    }

    &__text {
      // line-height: 1.8rem;
      margin-bottom: 1rem;
      font-size: 1.4rem;
    }

    &__tag {
      font-size: 1.2rem;
      display: inline-block;
      font-weight: normal;
      &::after {
        content: "/";
        margin: 0 0.5rem;
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }

  &__nav {
    text-align: center;

    @media (min-width: $md-width) {
      text-align: left;
    }
    &__title {
      padding-bottom: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.6rem;
    }

    &__item {
      display: block;
      text-decoration: none;
      padding-bottom: 1rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.4rem;
      text-transform: uppercase;
      margin: 2rem 0;

      @media (min-width: $md-width) {
        margin: 0;
      }
    }
  }

  &__image-social {
    margin: 4rem 0;
    text-align: center;
    padding: 2rem 3rem;

    @media (min-width: $md-width) {
      margin: 0;
    }

    &__img {
      width: 200px;
      height: 200px;
      border-radius: 10rem;
      overflow: hidden;
      margin: 0 auto 3rem auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__social {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
      }
      .icon {
        color: color(tertiary);
        font-size: 4rem;
        margin: 0 2rem;
        transition: color 0.3s ease;

        @media (min-width: $md-width) {
          font-size: 3rem;
          margin: 0 0.5rem;
        }

        &-facebook_header {
          font-size: 1.2rem;
        }

        &:hover {
          color: color(primary);
        }
      }
    }
  }

  &__seo {
    background-color: color(secondary);
    border-top: 1px solid color(border);
    padding: 4rem 5rem;
    text-align: center;

    @media (min-width: $md-width) {
      padding: 1rem 5rem;
    }

    &__tag {
      display: block;
      margin: 0 0 3rem 0;
      font-size: 1.2rem;
      line-height: 1rem;
      text-decoration: none;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $md-width) {
        display: inline-block;
        margin: 0 3rem;
        font-size: 1rem;
      }
    }
  }
  &__info {
    background-color: color(tertiary);
    color: color(mono, light);
    font-size: 1.4rem;
    font-weight: 500;
    padding: 3rem 2rem;
    text-align: center;

    @media (min-width: $md-width) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
    }

    &__contact {
      p {
        line-height: 2rem;
        text-align: center;
      }
    }

    &__cite {
      font-size: 1.6rem;
      text-align: center;
      margin: 3rem 0;

      @media (min-width: $md-width) {
        margin: 0;
      }
    }
  }
}
