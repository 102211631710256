.service-header {
  max-width: 1200px;
  margin: 3rem auto 0;
  text-align: center;
  padding: 0 2rem;
  &__title {
    color: color(primary);
    font-family: default(font-family, light);
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: 300;
    letter-spacing: 0.4rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 3rem;

    @media (min-width: $sm-width) {
      font-size: 6rem;
      line-height: 6.5rem;
    }

    @media (min-width: $xxl-width) {
      font-size: 6rem;
      line-height: 6.5rem;
    }
  }

  &__cite {
    font-family: default(font-family, light);
    font-size: 2.5rem;
    font-style: italic;
    line-height: 3rem;

    @media (min-width: $sm-width) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  &__author {
    font-size: 1.2rem;
  }

  &__description {
    margin: 2rem auto 3rem auto;
  }
}
