.titled-list {
  margin: 5rem auto !important;
  color: #000;
  left: 0;
  padding: 4vw 0 8vw;
  text-align: center;

  &-title {
    color: color(primary);
    font-weight: normal;
    margin-bottom: 4rem;
    display: block;
  }
}
