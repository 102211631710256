.text-with-floating-image {
  margin-bottom: 2rem !important;
  &__image {
    margin-bottom: 2rem;
    @media (min-width: $sm-width) {
      float: left;
      height: 50rem;
      margin-right: 2rem;
      width: 350px;
    }

    img {
      display: flex;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__clear {
    clear: both;
  }
}
