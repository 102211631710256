.top-header {
  background-color: color(tertiary);
  &__inner {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      color: color(mono, light);
      margin: 0 0.5rem;
    }
    .icon {
      font-size: 1rem;
      color: color(mono, light);
    }
  }
}
