input,
textarea {
  border: 1px solid color(primary);
  border-radius: default(radius);
  font-size: 2rem;
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  font-family: default(font-family, secondary);
  font-style: italic;
  outline: none;
  margin-bottom: 2rem;

  &:focus {
    outline: 2px solid color(primary);
  }
}

textarea {
  height: 14rem;
}
