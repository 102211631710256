.companies-slider {
  &__title {
    .h3 {
      color: color(primary);
      font-family: default(font-family, light);
      font-size: 3rem;
      font-weight: normal;
    }
  }

  &__cards {
    display: grid;
    gap: 2rem;
    justify-self: end;
    justify-content: center;
    align-self: center;
    justify-self: center;
    margin-top: 2rem;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 600px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $md-width) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $lg-width) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.company-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  &__name {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__description {
    margin-bottom: 1rem;
  }

  &__logo {
    width: 15rem;
    height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
}
