@import "Header/Header";
@import "Footer/Footer";

.generic-layout {
  &__content {
    // padding-top: 7rem;

    min-height: 100vh;
    &.loading {
    }
  }
}
