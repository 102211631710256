.image-with-title-and-description {
  margin-bottom: 2rem;
  @media (min-width: $sm-width) {
    padding-left: 4rem !important;
  }
  &__images {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    @media (min-width: $sm-width) {
      grid-template-columns: 1fr 1fr;
    }

    &__img {
      max-height: 30rem;
      border-radius: default(radius);
      overflow: hidden;
      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
