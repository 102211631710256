@import "Nav/Nav";
@import "MobileNav/MobileNav";

.main-header {
  align-items: center;
  background-color: color(primary);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;

  &__nav-ctn {
    display: none;
    @media (min-width: $md-width) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &-content {
    display: flex;
    font-family: default(font-family);
    height: 100%;
    justify-content: space-between;
    margin: auto;
    max-width: 2000px;
    padding: 0 2rem;
    height: 6rem;
  }

  &__social {
    display: none;
    margin-left: 0.5rem;
    @media (min-width: $md-width) {
      display: flex;
      align-items: center;
    }
    &__link {
      color: color(mono, light);
      text-decoration: none;
      margin: 0 0.5rem;
      transition: color 0.3s ease;
      display: flex;
      align-items: center;

      &:hover {
        color: color(mono, darksoft) !important;
      }

      .icon {
        font-size: 2rem;

        &-facebook_header {
          font-size: 1.5rem;
        }
      }
    }
  }

  &-mobile-btn {
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (min-width: $md-width) {
      display: none;
    }

    .icon {
      font-family: "icomoon";
      font-size: 4rem;

      &.open {
        &::before {
          content: "\e905";
        }
      }
    }
  }

  &-logo {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 18rem;
    @media (min-width: $md-width) {
      width: 22rem;
    }

    @media (min-width: $lg-width) {
      width: 24rem;
    }
    @media (min-width: $xxxl-width) {
      width: 26rem;
    }

    &-img {
      width: 100%;
      display: flex;
    }
  }

  &__account {
    margin-left: 3rem;
    display: none;
    @media (min-width: $md-width) {
      display: flex;
      align-items: center;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    .icon {
      color: white;
      font-size: 3rem;
    }
  }
}
