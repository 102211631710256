.encarts {
  width: 90%;
  max-width: 1200px;
  margin: 5rem auto 0 auto;
  border-radius: 0 2rem;
  overflow: hidden;

  @media (min-width: $sm-width) {
  }

  a {
    text-decoration: none;
  }

  &-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    @media (min-width: $sm-width) {
      flex-direction: row;
      margin-bottom: 0;
    }

    &.right {
      flex-direction: column;

      @media (min-width: $sm-width) {
        flex-direction: row-reverse;
      }
    }

    &-image {
      width: 100%;
      height: 60vw;

      @media (min-width: $sm-width) {
        height: auto;
      }

      img {
        display: flex !important;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 60vw;
      background-color: color(primary);
      gap: 2rem;

      @media (min-width: $sm-width) {
        height: auto;
      }

      &-title {
        color: color(mono, light);
        padding-bottom: 0;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
      }
      &-description {
        color: color(mono, light);
        font-style: italic;
        margin: 0;
        padding: 0;
      }
    }
  }
}
