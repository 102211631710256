.presentation {
  position: relative;
  margin: 5rem auto;
  max-width: 1300px;
  top: 0;
  box-sizing: border-box;
  border: 1px solid transparent;
  width: calc(100% + 2px);
  left: -1px;

  @media (min-width: $sm-width) {
    width: 90%;
  }

  &__header {
    margin: 5rem 0;
    z-index: 5;
    position: relative;
    &__title {
      color: color(primary);
      font-family: default(font-family, secondary);
      font-weight: 300;
      position: relative;

      text-align: center;
      font-size: 6rem;
      line-height: 6rem;
      letter-spacing: 0.1rem;
      margin-left: -1.2rem;
    }
    &__subtitle {
      font-family: default(font-family, secondary);
      display: block;
      font-size: 3.5rem;
      line-height: 3.5rem;
      margin-top: 1.5rem;
    }
  }

  &__content {
    text-align: center;
    color: color(mono, light);
    position: relative;
    margin-bottom: 5rem;

    &__inner {
      padding: 2rem;
    }
    &__title {
      font-family: default(font-family, fancy);
      font-size: 5.5rem;
      line-height: 5.5rem;
      z-index: 5;
      position: relative;
      margin-bottom: 2rem;
    }

    &__subtitle p {
      font-family: default(font-family, secondary);
      font-size: 3rem;
      line-height: 3rem;
      font-weight: 900;
      position: relative;
      z-index: 5;
    }

    &__text p {
      font-family: default(font-family, light);
      // font-family: "Josefin Sans", sans-serif;
      font-size: 2rem;
      line-height: 2rem;
      position: relative;
      z-index: 5;
      margin: 2rem 0;
      font-weight: 600;
    }

    &__button {
      position: relative;
      z-index: 5;
    }

    &__bg {
      position: absolute;
      top: 0;
      background-color: color(tertiary);
      width: 100%;
      height: 100%;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &__image {
      width: 50%;
      overflow: hidden;
      height: 100%;
      img {
        margin-top: -3rem;
        width: 140%;
        height: 140%;
        object-fit: cover;
      }
    }
  }
}
