.about {
  &-image {
    float: left;
    height: 40rem;
    margin-right: 2rem;
    width: 35%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
