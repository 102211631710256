.accordion {
  &-tab {
    margin-bottom: 0.5rem;
    &.active {
      .accordion-label {
        background-color: color(tertiary);
        color: color(mono, light);

        &::after {
          color: color(mono, light);
          -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }
  }
  &-label {
    cursor: pointer;
    user-select: none;
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 1rem;
    border-radius: default(radius);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: "\e901";
      float: right;
      font-family: "icomoon";
      font-size: 1.4rem;
      font-weight: 100;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      margin-left: 2rem;
      transition: color 0.3s ease;
    }

    &:hover {
      background-color: color(tertiary);
      opacity: 0.8;
      color: color(mono, light);

      &::after {
        color: color(mono, light);
      }
    }
  }

  &-content {
    overflow: hidden;
    transition: max-height 0.3s ease;

    &__inner {
      padding: 0 0 0 2rem;
      margin: 1rem 0 2rem 0;
    }
  }
}
