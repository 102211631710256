.portfolio {
  top: 4rem;
  display: block;
  position: relative;
  &__container {
    @media (min-width: $sm-width) {
      display: grid;
      grid-template-columns: 14rem auto;
      padding: 0 4rem;
    }
  }

  &__menu {
    top: 60px;
    position: -webkit-sticky;
    position: sticky;
    background-color: color(mono, light);
    @media (min-width: $sm-width) {
      padding-right: 2rem;
      top: 0;
      position: -webkit-sticky;
      position: relative;
    }

    &__ctn {
      @media (min-width: $sm-width) {
        top: 150px;
        position: -webkit-sticky;
        position: sticky;
      }
    }

    &__title {
      display: none;

      @media (min-width: $sm-width) {
        display: none;
        font-size: 4rem;
        line-height: 4rem;
      }
    }
    &__nav {
      white-space: nowrap;
      overflow-x: auto;
      width: 100%;
      margin-bottom: 2rem;
      padding: 1rem 2rem;

      @media (min-width: $sm-width) {
        padding: 0;
      }

      &__item {
        font-size: 1.4rem;
        display: inline-block;
        margin: 0 2rem 0 0;

        @media (min-width: $sm-width) {
          margin: 0 0 0.8rem 0;
          display: block;
        }

        &.active {
          color: color(primary);
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
  &__images {
    @media (min-width: $sm-width) {
      padding-left: 2rem;
    }
  }
}
