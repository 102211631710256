.big-title {
  display: none;
  padding: 0 2rem;

  @media (min-width: $md-width) {
    padding: 0 3rem;
  }
  &-title {
    h1 {
      color: color(primary);
      font-family: default(font-family, secondary);
      font-size: 3rem;
      line-height: 3.5rem;
      font-weight: 300;
      letter-spacing: 0.4rem;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      @media (min-width: $sm-width) {
        font-size: 4rem;
        line-height: 4.5rem;
      }

      @media (min-width: $xxl-width) {
        font-size: 5rem;
        line-height: 5.5rem;
      }
    }
  }

  &-subtitle p {
    color: color(primary);
    font-family: default(font-family);
    font-size: 1.6rem;
    text-align: center;
    margin-top: 1rem;
    font-family: "Shadows Into Light", cursive;

    @media (min-width: $sm-width) {
      font-size: 1.8rem;
    }

    @media (min-width: $xxl-width) {
      font-size: 2.5rem;
    }
  }
}
