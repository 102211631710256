.cite-block {
  background-color: color(tertiary);
  margin-top: 4rem;
  padding: 10rem 2rem 8rem 2rem;

  @media (min-width: $sm-width) {
    padding: 8rem 0 6rem;
  }

  &-content {
    margin: auto;
    max-width: 800px;
    text-align: center;

    &-text {
      color: color(mono, light);
      font-size: 3.4rem;
      font-weight: 700;

      &::before {
        content: "«";
      }

      &::after {
        content: "»";
      }
    }

    &-author {
      color: color(mono, light);
      font-size: 1.6rem;
      margin-top: 2rem;
      font-weight: 600;
      padding-right: 1rem;
      text-align: right;
      font-family: default(font-family, secondary);
    }
  }
}
