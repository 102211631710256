@use "sass:color";
@use "sass:map";

// Breakpoint
$xxxl-width: 1700px;
$xxl-width: 1500px;
$xl-width: 1320px;
$lg-width: 1200px;
$md-width: 992px;
$sm-width: 700px;
$xs-width: 480px;
$xxs-width: 360px;
$gutter: 30px;
$breadcrumb-height: 6rem;

// Grid
$grid-columns: 12;
$column-width: calc(100 / $grid-columns);
$breakpoints: (
  xxs: (
    container: 100%,
    container-small: 100%,
    gutter: 2rem,
    gutter-ctn: 2rem,
  ),
  xs: (
    container: 100%,
    container-small: 100%,
    min-width: $xs-width,
    gutter: $gutter,
    gutter-ctn: 2rem,
  ),
  sm: (
    container: 100%,
    container-small: 100%,
    min-width: $sm-width,
    gutter: $gutter,
    gutter-ctn: 4.5rem,
  ),
  md: (
    container: 960px,
    container-small: 960px,
    min-width: $md-width,
    gutter: $gutter,
    gutter-ctn: 4.5rem,
  ),
  lg: (
    container: 1170px,
    container-small: 1170px,
    min-width: $lg-width,
    gutter: $gutter,
    gutter-ctn: 4.5rem,
  ),
  xl: (
    container: 1290px,
    container-small: 1290px,
    min-width: $xl-width,
    gutter: $gutter,
    gutter-ctn: 4.5rem,
  ),
  xxl: (
    container: 1500px,
    container-small: 1390px,
    min-width: $xxl-width,
    gutter: $gutter,
    gutter-ctn: 4.5rem,
  ),
  xxxl: (
    container: 1800px,
    container-small: 1800px,
    min-width: $xxxl-width,
    gutter: $gutter,
    gutter-ctn: 4.5rem,
  ),
);
$colors: (
  mono: (
    light: #fff,
    lightsoft: rgba(#fff, 0.5),
    dark: #000,
    darksoft: rgba(#000, 0.5),
    darksofthover: rgba(#000, 0.1),
  ),
  primary: (
    base: #f3c161,
    medium: rgba(#f3c161, 0.9),
    light: rgba(#f3c161, 0.9),
    ultralight: rgba(#f3c161, 0.6),
  ),
  secondary: (
    base: #faf6ed,
    dark: #faf6ed,
    medium: #faf6ed,
    light: #faf6ed,
  ),
  tertiary: (
    base: #b3cfc3,
    dark: #b3cfc3,
    medium: #b3cfc3,
    light: rgba(#b3cfc3, 0.6),
  ),
  quaternary: (
    base: #d1c8c1,
  ),
  text: (
    base: #000000,
    light: #ffffff,
    medium: rgb(74, 73, 73),
    dark: rgb(33, 33, 33),
  ),
  alert: (
    warning: orange,
    danger: #e90a4e,
    success: #76d46c,
  ),
  grey: (
    base: #cbc8bf,
    medium: #7e8164,
  ),
  shadow: (
    base: rgb(3, 3, 3),
  ),
  border: (
    base: #cbc8bf,
    light: rgb(180, 151, 151),
    medium: rgb(180, 151, 151),
    dark: rgb(180, 151, 151),
  ),
  background: (
    base: #cbc8bf,
    light: rgb(205, 204, 204),
    medium: rgb(142, 142, 142),
    dark: rgb(0, 0, 0),
    dark-light: rgba(0, 0, 0, 0.477),
    dark-ultralight: rgba(0, 0, 0, 0.156),
  ),
  button: (),
);

@function color($color, $tone: "base") {
  @return map-get(map-get($colors, $color), $tone);
}

$size-desktop-1: 2rem;
$default: (
  font-size: (
    base: 1.6rem,
    small: 1.3rem,
    mobile: 1.2rem,
    desktop: 1.6rem,
    button: 2rem,
  ),
  font-family: (
    base: "raleway",
    //primary
    light: "Josefin Sans Light",
    secondary: "Josefin Sans",
    tertiary: "moontime",
    //secondary
    fancy: "clattering",
    //tertiary
    icon: "icomoon",
    //icons
  ),
  weight: (
    base: 400,
    light: 300,
    bold: 600,
  ),
  radius: (
    base: 0.3rem,
    medium: 1rem,
    big: 10rem,
  ),
  line-height: (
    base: 2.2rem,
    small: 1.8rem,
  ),
);

@function default($property, $value: "base") {
  @return map-get(map-get($default, $property), $value);
}

$heights: (
  banner: (
    "base": 3rem,
    "xs": 4rem,
    "sm": 4rem,
    "md": 4rem,
    "lg": 4rem,
    "stack": 4rem,
  ),
  header: (
    "base": 14rem,
    "xs": 14rem,
    "sm": 14rem,
    "md": 16.7rem,
    "lg": 18.546rem,
    "nomenu": 13.6rem,
    "stack": 5.7rem,
    "stack-mobile": 7.2rem,
  ),
);

@function height($type, $size: "base") {
  @return map-get(map-get($heights, $type), $size);
}

$icons: (
  arrow-down: "\f078",
);

@function icon($icon) {
  @return map-get($icons, $icon);
}
