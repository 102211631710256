.home-footer {
  margin-top: 10rem;
  &__images {
    display: flex;
    align-items: center;
    justify-content: center;
    &__img {
      width: 30rem;
      height: 30rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
