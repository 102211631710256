.slider-one {
  .swiper-button-prev,
  .swiper-button-next {
    color: color(primary);
    margin-top: 4rem;

    &:after {
      content: "\e918";
      font-size: 5rem;
      font-family: "icomoon";
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
  }
  .swiper-pagination-bullet {
    background-color: color(primary);
  }
}
