.mobile-nav {
  background-color: color(primary);
  position: absolute;
  right: -100%;
  width: 100%;
  height: 100vh;
  transition: right 0.3s ease;
  padding-top: 1rem;
  overflow-y: auto;
  padding-bottom: 15rem;

  @media (min-width: $md-width) {
    display: none;
  }

  &.open {
    right: 0;
  }

  &__item,
  &__submenu__item {
    display: block;
    border-bottom: 1px solid color(tertiary, light);

    &:first-child {
      border-top: 1px solid color(tertiary, light);
    }
    &__link {
      color: color(mono, light);
      font-family: default(font-family);
      display: block;
      height: 100%;
      justify-content: center;
      padding: 2rem;
      text-decoration: none;
      font-variant: small-caps;
      font-size: 1.6rem;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        color: color(mono, light) !important;
      }
      &.parent {
        &::after {
          content: "\e901";
          float: right;
          font-family: "icomoon";
          font-size: 1.4rem;
          font-weight: 100;
          -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
          margin-left: 2rem;
        }
      }

      &.parent.open {
        &::after {
          -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__submenu {
    background-color: rgb(143, 138, 98);
    overflow: hidden;
    transition: max-height 0.3s ease;

    &__item {
      &__link {
        padding-left: 4rem;
      }
    }
  }

  &__social {
    text-align: center;
    margin-top: 6rem;
    &__link {
      color: color(mono, light);
      text-decoration: none;
      margin: 0 3rem;
      transition: color 0.3s ease;

      &:hover {
        color: color(mono, dark) !important;
      }

      .icon {
        font-size: 2.4rem;
      }
    }
  }
}
