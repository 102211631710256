.contact {
  &__header {
    margin: auto;
    // padding-bottom: 3rem;
    width: 90%;

    @media (min-width: $sm-width) {
    }

    .main-title {
      margin-bottom: 2rem;

      &__description {
        margin-top: 2rem;
      }
    }
  }

  &__container {
    margin: auto;

    @media (min-width: $sm-width) {
      align-items: flex-start;
      display: flex;
    }
  }
  &__info {
    width: 100%;
    padding: 0 2rem;
    @media (min-width: $sm-width) {
      width: 50%;
    }
  }
  &__form {
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 3rem;
    text-align: center;
    @media (min-width: $sm-width) {
      width: 50%;
    }

    form {
      width: 100%;
    }

    .btn {
      cursor: pointer;
    }
  }
}
