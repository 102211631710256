@import "fonts/fonts";

.font-primary {
  font-family: default(font-family);
  font-weight: default(weight, medium);
}

.font-primary-light {
  font-family: default(font-family, light);
  font-weight: 100;
}

.font-secondary {
  font-family: default(font-family, secondary);
}

.font-tertiary {
  font-family: default(font-family, fancy);
}

p,
span {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: default(font-family);
}

.cite {
  font-size: 3rem;
  font-style: italic;
  line-height: 3.4rem;
}

.text {
  font-family: default(font-family);
  font-size: 1.4rem;
  line-height: 2.4rem;

  &-big {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  &-small {
    font-size: 1rem;
  }

  &-center {
    text-align: center !important;
  }

  &-left {
    text-align: left !important;
  }

  &-right {
    text-align: right !important;
  }
}

.upc {
  text-transform: uppercase;
}

.lwc {
  text-transform: lowercase;
}

.cap {
  text-transform: capitalize;
}

.h1,
h1 {
  // color: color(primary);
  // font-family: default(font-family, secondary);
  // font-size: 3rem;
  // line-height: 3.5rem;
  // font-weight: 300;
  // letter-spacing: 0.4rem;
  // position: relative;
  // text-align: center;
  // // text-transform: uppercase;

  // @media (min-width: $sm-width) {
  //   font-size: 4rem;
  //   line-height: 4.5rem;
  // }

  // @media (min-width: $xxl-width) {
  //   font-size: 5rem;
  //   line-height: 5.5rem;
  // }
}

.h2,
h2 {
  font-family: default(font-family, secondary);
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 1rem;
}

.h3,
h3 {
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
  font-family: default(font-family, secondary);
  line-height: 4rem;
  @media (min-width: $md-width) {
    font-size: 4rem;
  }
}

.h4,
h4 {
  font-family: default(font-family);
  font-size: 2.5rem;
  font-weight: 500;
  padding-bottom: 2rem;
}

.h5,
h5 {
  font-family: default(font-family);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.6rem;
  padding-bottom: 2rem;
}

.h6,
h6 {
  font-family: default(font-family);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.6rem;
  padding-bottom: 2rem;
}

strong {
  font-weight: 700;
}

.strong-medium {
  font-weight: 500;
}

a {
  color: inherit;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    color: color(primary);
  }
}

.fancy-title {
  color: color(primary);
  font-family: default(font-family, fancy);
  font-size: 5rem;
  line-height: normal;
  padding: 0;
  width: 100%;
  text-align: center;

  @media (min-width: $sm-width) {
    font-size: 6.5rem;
  }

  @media (min-width: $md-width) {
    font-size: 8rem;
  }
}

.font-primary {
  font-family: default(font-family);
}

.font-primary-medium {
  font-family: default(font-family);
}

.font-secondary {
  font-family: default(font-family, secondary);
}

.font-secondary-light {
  font-family: default(font-family, light);
}

.style-title {
  font-family: inherit;
  font-weight: default(weight, medium);
}

strong,
b {
  font-weight: default(weight, medium);
}

.barre {
  text-decoration: line-through;
}

.body-regular {
  font-size: 1.6rem;
}

.body-large {
  font-size: 1.8rem;
}

.body-small {
  font-size: 1.4rem;
}
