.block-video {
  &__title {
    color: color(primary);
    font-family: default(font-family, light);
    font-size: 3rem;
    font-weight: normal;
  }

  &__description {
    display: none;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
    @media (min-width: $sm-width) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem 2rem;
    }

    @media (min-width: $md-width) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__card {
    display: flex;
    // align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    &__title {
      .h4 {
        font-size: 1.6rem;
      }
    }
  }
  &__inner {
    display: grid;
    aspect-ratio: 2;
    grid-template-columns: 1fr;

    iframe {
      display: flex;
      height: 100%;
      width: 100%;
    }
  }
}
