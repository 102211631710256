.short-biography {
  display: none;
  &-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1800px;
    padding: 0 2rem;
    position: relative;

    @media (min-width: $md-width) {
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0 3rem;
    }
  }

  &-content {
    width: 100%;

    @media (min-width: $md-width) {
      width: 40%;
    }

    h3 {
      @extend p;
      text-align: center !important;
      font-size: 2.4rem !important;
      font-weight: 700;
      font-family: default(font-family, secondary) !important;
      padding-bottom: 2rem;

      @media (min-width: $xs-width) {
        text-align: left !important;
      }
    }

    p {
      text-align: justify;

      b {
        font-family: default(font-family, secondary);
        font-size: 1.4rem;
        font-weight: 700;
      }
    }

    &-img {
      border-radius: 0.5rem 0.5rem;
      height: 200px;
      width: 200px;
      margin: 0 auto 2rem;
      overflow: hidden;

      @media (min-width: $xs-width) {
        float: left;
        margin: 0 1.5rem 0.5rem 0;
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .text {
      line-height: 2rem;
    }
    .btn-ctn {
      text-align: center;
      .btn {
        margin: 4rem auto 2rem;
        // width: 280px;

        @media (min-width: $md-width) {
          margin: 2rem auto;
        }
      }
    }
  }

  &-cite {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    overflow: hidden;
    text-align: center;
    width: 100%;

    @media (min-width: $md-width) {
      display: block;
      margin-top: 0;
      width: 50%;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }

    .cite {
      display: block;
      margin-top: 2rem;

      @media (min-width: $md-width) {
        margin-bottom: 4rem;
      }

      p {
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        @media (min-width: 486px) {
          font-size: 2.7rem;
        }

        @media (min-width: 530px) {
          font-size: inherit;
        }

        br {
          display: none;
          @media (min-width: 530px) {
            display: block;
          }
        }
      }
    }

    &-img {
      margin: 4rem auto 0 auto;
      right: 0;
      width: 20rem;
      @media (min-width: $md-width) {
        margin-top: auto;
        // position: absolute;
      }

      img {
        width: 100%;
      }
    }
  }
}
