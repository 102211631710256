.reviews {
  width: 90%;
  max-width: 1260px;
  margin: 5rem auto 0 auto;
  &-cards {
    margin: 4rem 0 1rem 0;
    overflow-x: visible;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: $sm-width) {
      padding: 0;
      width: 100%;
    }

    &__button-prev,
    &__button-next {
      cursor: pointer;

      .icon {
        color: color(primary);
        font-weight: 900;
        font-size: 2rem;
      }
    }

    &__button-prev {
      .icon {
        display: block;
        transform: rotate(180deg);
      }
    }
  }
  &-card {
    vertical-align: top;
    box-sizing: border-box;
    width: 90%;
    margin: auto;

    @media (min-width: $sm-width) {
      width: 100%;
    }
    span {
      display: block;
      color: color(primary);
    }
    &-name {
      font-weight: bold;
    }
    &-category {
      font-style: italic;
    }
    &-comment {
      font-style: italic;
      margin-top: 1rem;
    }
  }
  .btn {
    text-transform: none;
  }

  .slider-one {
    margin: 0 1rem;
    .swiper-pagination-bullets {
      position: relative;
      margin-top: 2rem;
    }
  }
}
