.portfolio-images {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;

  @media (min-width: $sm-width) {
    grid-gap: 1rem;
  }

  .image-card {
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    transition: filter 0.3s ease;
    overflow: hidden;
    background-color: color(tertiary);
    color: color(tertiary);
    position: relative;

    .react_lightgallery_item {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      transition: all 1s ease;
      z-index: 2;
    }

    &__logo {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &__inner {
        width: 60%;
      }
      &__img {
        width: 100%;
      }
    }
  }

  &__show-more {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
}
