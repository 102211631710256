.landingpage {
  width: 96%;
  max-width: 1260px;
  margin: 5rem auto;

  &__header {
    position: relative;
    background-color: color(quaternary);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: $sm-width) {
      flex-direction: row;
      padding: 4rem;
    }

    &__image {
      display: block;
      position: relative;
      width: 100%;
      margin-top: 3rem;
      @media (min-width: $sm-width) {
        width: 46%;
        margin-top: 0;
      }

      img {
        max-width: 100%;
      }
    }

    &__title {
      font-size: 4rem;
      letter-spacing: 0.2rem;
      line-height: 5rem;
      font-weight: 800;
      color: color(mono, light);
      text-align: center;

      width: 100%;
      @media (min-width: $sm-width) {
        font-size: 5rem;
        line-height: 6.4rem;
        width: 46%;
        text-align: left;
      }
    }
  }

  &__content {
    margin-top: 2rem;

    @media (min-width: $md-width) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }

    &__description {
      @media (min-width: $md-width) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 4rem;
        width: 80%;
      }
    }

    &__btn {
      text-align: center;

    }
    &__links {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 4rem;
      margin-top: 4rem;
      text-align: center;

      @media (min-width: $xs-width) {
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem 2rem;
      }

      @media (min-width: $md-width) {
        width: 100rem;
        margin-top: 0;
      }
    }
  }
  &__link-card {
    width: 100%;
    padding-top: 100%;
    height: 0;
    position: relative;
    margin: auto;

    @media (min-width: $xs-width) {
      width: 100%;
      padding-top: 100%;
    }

    a {
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }
    }

    img {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__label {
      position: absolute;
      background-color: color(quaternary);
      width: 80%;
      left: 10%;
      bottom: -2rem;
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      padding: 1rem 0;
      letter-spacing: 0.15rem;
    }
  }
}
