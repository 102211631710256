.main-title {
  &__title {
    color: color(primary);
    font-family: default(font-family, light);
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: normal;
    letter-spacing: 0.4rem;
    position: relative;
    text-align: center;
    margin: 3rem 0 3rem 0;

    @media (min-width: $sm-width) {
      font-size: 6rem;
      line-height: 6.5rem;
    }

    @media (min-width: $xxl-width) {
      font-size: 6rem;
      line-height: 6.5rem;
    }
  }

  &__description {
    padding-bottom: 2rem;
    text-align: center;
  }
}
