.images-grid {
  &__title {
    .h3 {
      color: color(primary);
      font-family: default(font-family, light);
      font-size: 3rem;
      font-weight: normal;
    }
  }
  &__container {
    position: relative;
  }

  &__button {
    position: absolute;
    top: 44%;
    color: color(primary);
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: color(tertiary);
    }

    &-prev {
      left: -4rem;
      transform: rotate(180deg);
    }

    &-next {
      right: -4rem;
    }

    .icon {
      font-size: 3rem;
      font-weight: norma;
    }
  }
  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    @media (orientation: portrait) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $sm-width) and (orientation: landscape) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .react_lightgallery_item {
      aspect-ratio: 1;
      width: 100%;
      height: 100%;
    }

    &__image {
      width: 100%;
      height: 100%;
      background-color: color(tertiary);
      color: color(tertiary);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .slider-one {
    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        font-size: 2rem;
      }
    }
    .swiper-pagination-bullets {
      position: relative;
      margin-top: 2rem;
    }
  }
}
