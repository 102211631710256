.cross-selling {
  background-color: color(tertiary);
  text-align: center;
  color: color(mono, light);

  &__inner {
    padding: 4rem !important;
    margin-bottom: 0 !important;
  }
  &__title {
    margin-bottom: 4rem;
    .h3 {
      font-family: default(font-family, light);
    }
  }
  &__cards {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    @media (min-width: $sm-width) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $md-width) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__card {
    a {
      text-decoration: none;

      &:hover {
        color: inherit;
      }
    }
    &__image__container {
      position: relative;
      overflow-y: hidden;

      @media (min-width: $sm-width) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: $md-width) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &__image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__title {
      .h3 {
        font-size: 2.6rem;
        padding-bottom: 0;
        margin: 1rem 0 0 0;
        line-height: 2.6rem;
      }
    }
  }
}
