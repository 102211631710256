.rich-text {
  p {
    margin-bottom: 2rem;
  }

  h4 {
    line-height: 3rem;
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    padding-left: 2rem;
    li {
      margin-bottom: 1rem;

      &::marker {
        color: color(quaternary);
      }
    }
  }
}
