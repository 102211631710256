@import "BigTitle/BigTitle";
@import "ShortBiography/ShortBiography";
@import "SeoOne/SeoOne";
@import "Reviews/Reviews";
@import "Cite/Cite";
@import "Encarts/Encarts";
@import "Carousel/Carousel";
@import "TitledList/TitledList";
@import "Accordion/Accordion";
@import "ImagesGrid/ImagesGrid";
@import "SliderBig/SliderBig";
@import "TextWithFloatingImage/TextWithFloatingImage";
@import "RichTextSlice/RichTextSlice";
@import "ImageWithTitleAndDescription/ImageWithTitleAndDescription";
@import "PortfolioImages/PortfolioImages";
@import "PresentationBiography/PresentationBiography";
@import "ServiceHeader/service-header";
@import "LinkButton/link-button";
@import "BlockVideo/block-video";
@import "CompaniesSlider/companies-slider";
@import "CrossSelling/cross-selling";
@import "LandingPage/LandingPage";

.slice {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 2rem;
  width: 100%;
  padding: 0 2rem;

  @media (min-width: $sm-width) {
    margin-bottom: 5rem;
  }

  &-full {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
