.header-separator {
  height: 6rem;
}

/* grid & space */

hr {
  border-top: 1px solid color(primary);
}

.no-underline {
  text-decoration: none !important;
}

.cursor {
  cursor: pointer;
}

.border {
  border: 1px solid color(border);
}

.valign-middle {
  > * {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}

.md-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 107rem;
}

.sm-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 65rem;
}

.xs-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 45rem;
}

.unvisible {
  display: none;
}

/* bg color */
.bg-white {
  background-color: color(mono, light);
}

.bg-black {
  background-color: color(mono, dark);
  color: color(mono, light);
}

.bg-grey {
  background-color: color(background);
}

.bg-tertiary {
  background-color: color(tertiary);
}

.bg-tertiary-light {
  background-color: rgba(color(tertiary), 0.1);
}

.color-secondary {
  color: color(secondary);
}

.color-tertiary {
  color: color(tertiary);
}

/* links */
.link {
  &:hover {
    text-decoration: underline;
  }
}

.link2 {
  position: relative;

  &::after {
    background-color: color(text);
    bottom: -0.5rem;
    content: "";
    height: 0.05rem;
    left: 0;
    position: absolute;
    transition: all 0.3s linear;
    width: 100%;
  }

  &:hover {
    &::after {
      background-color: color(primary);
    }
  }
}

// autres helper
.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.position-relative {
  position: relative;
}

.position-abolute {
  position: absolute;
}

.underline {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.barre {
  text-decoration: line-through;
}

.upper {
  text-transform: uppercase;
}

.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.nw {
  white-space: nowrap;
}

.cover {
  background-size: cover;
}

.no-repeat {
  background-repeat: no-repeat;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-responsive {
  max-width: 100%;
}

.img-responsive-ib {
  max-width: 100%;
}

.fix-ht-img {
  font-size: 0;
  line-height: 0;
}

.strong {
  font-weight: default(weight, bold);
}

.strong-medium {
  font-weight: default(weight, medium);
}

.no-margin {
  margin: 0 !important;
}

.help-block {
  color: color(text);
  font-size: 1.4rem;
  line-height: 1.6;
}

.break {
  word-break: break-all;
}

.separator {
  margin-bottom: 3rem;
  text-align: center;
  svg {
    height: 1.5rem;
    line {
      stroke: color(primary);
      stroke-width: 2;
    }
  }
}
