.navigator {
  display: none;
  height: 100%;

  @media (min-width: $md-width) {
    display: block;
  }

  &-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &-item {
    height: 100%;
    list-style: none;

    &-link {
      align-items: center;
      color: color(mono, light);
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 0 1rem;
      text-decoration: none;
      // text-transform: uppercase;
      font-variant: small-caps;
      transition: color 0.3s ease, background-color 0.3s ease;

      &__label {
        margin-top: 0.5rem;
        font-family: default(font-family, secondary);
        font-size: 1.6rem;
        @media (min-width: $lg-width) {
          font-size: 2rem;
        }
      }

      &:hover,
      &:active,
      &:focus {
        background-color: color(primary, ultralight);
        color: color(mono, dark) !important;
      }
    }

    &:hover {
      .navigator-item-link {
        background-color: color(primary, ultralight);
        color: color(mono, dark) !important;
      }
    }
  }

  &-submenu {
    align-items: center;
    background-color: color(primary);
    display: grid;
    font-size: $size-desktop-1;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem 5rem;
    height: auto;
    left: 0;
    opacity: 0%;
    padding: 3rem;
    position: absolute;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    visibility: hidden;
    width: 100vw;

    &.open {
      opacity: 100%;
      visibility: visible;
    }

    &-item {
      border-radius: 0.3rem;
      display: inline-block;
      // border: 1px solid red !important;

      &-link {
        align-items: center;
        color: color(mono, light);
        display: flex;
        text-decoration: none;
        transition: color 0.3s ease, background-color 0.3s ease;

        &:hover > *,
        &:active,
        &:focus > * {
          background-color: color(primary, ultralight) !important;
          color: color(mono, dark) !important;
        }
      }

      &-img {
        border-radius: 20rem;
        display: block;
        height: 6rem;
        overflow: hidden;
        width: 6rem;
        min-width: 6rem;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      &-content {
        margin-left: 1.5rem;

        span {
          color: color(secondary);
          font-size: 1.6rem;
          line-height: 2.4rem;
          margin: 0;
          font-variant: small-caps;

          @media (min-width: $lg-width) {
            font-size: 2rem;
          }
        }

        p {
          color: color(secondary);
          font-size: 1.2rem;
          line-height: 1.4rem;

          @media (min-width: $lg-width) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
